<i18n locale="th" lang="yaml" >
page.title : "Ticket ที่เสร็จสิ้น"
page.description : "รายการของ Ticket ที่ได้ดำเนินการเสร็จสิ้นแล้ว"
</i18n>
<template>
	<div class="page ticket-page">
		<my-page-header
			:title="$t('page.title',{ name: company.name })"
			:description="$t('page.description',{ name: company.name })"/>
		<a-card :bordered="false">
			<TicketTableMode
				mode="recent"
				filter-name="report_by_company_id"
				:filter-value="$route.params.id"
				:back-link="{ name: 'company/tickets',params: { id: $route.params.id } }"/>
		</a-card>
	</div>
</template>

<script>
import TicketTableMode from "@components/ticket/TicketTableMode.vue"
export default {
	components : {
		TicketTableMode,
	} ,
	props : {
		company : {
			type : Object,
			default : () => []
		}
	}
}
</script>
